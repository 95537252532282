import React, {useEffect} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import '../../Styles/main.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function AboutUs (){
  useEffect(() => {
        AOS.init();
      }, [])
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active href="/about-us">About Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="about-area1 pt-5 pb-5" data-aos="fade-up">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-header">
          <h2>Himalayan Healers Spa</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
            <div className="about-caption about-caption1">

              <div className="section-tittle">
                <p className="mb-3">
                  Welcome to Himalayan Healers Spa, where tranquility meets rejuvenation. Established in 2006, we take pride in being a sanctuary of serenity nestled in the heart of the Himalayas. Our journey began with a vision to create a haven for relaxation, wellness, and healing, drawing inspiration from the natural beauty and timeless traditions of the majestic Himalayan region.
                </p>
                <p>
                  At Himalayan Healers Spa, we believe in the transformative power of touch and the therapeutic benefits of ancient healing techniques. Our skilled and compassionate therapists are dedicated to providing a unique and personalized experience, tailored to each individual's needs. Whether you seek relief from stress, muscular tension,
                   or simply a blissful escape, our spa is designed to cater to your well-being.</p>
                <p>Immerse yourself in the calming ambiance of our spa, where soothing aromas, soft lighting, and tranquil music create an atmosphere 
                conducive to deep relaxation. Our carefully curated menu of massage services combines traditional wisdom with modern techniques, offering a holistic approach to wellness. From the healing touch of Himalayan stone massages to the invigorating energy of aromatherapy, 
                each treatment is crafted to restore balance to the mind, body, and spirit.</p>
                <p>Over the years, Himalayan Healers Spa has become a symbol of excellence in the wellness industry. Our commitment to quality,
                 professionalism, and guest satisfaction has garnered us a loyal clientele who trust us to deliver an exceptional spa experience. We continuously strive to enhance our offerings, incorporating the latest advancements in holistic health and wellness 
                 to ensure that our guests receive the best possible care.</p>
                  <p>As stewards of well-being, we also value our connection to the community and the environment. We source natural, sustainable, and locally sourced ingredients for our treatments, reflecting our 
                  commitment to both personal and planetary health.</p>
                <p>Thank you for choosing Himalayan Healers Spa as your destination for relaxation and rejuvenation. Whether you are a 
                seasoned spa enthusiast or a first-time visitor, we look forward to guiding you on a journey of self-discovery and renewal. Step into our oasis,
                 where the essence of the Himalayas meets the art of healing, and let us be your companions on the path to wellness.</p>
              </div>
              <div className="double-btn d-flex flex-wrap">
                <a href="#" className="border-btn">Quik Enquiry</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

function HomeAboutUs (){
  useEffect(() => {
        AOS.init();
      }, [])
  return(
    <>
    <section className="about-area1 section-bg pt-5 pb-5" data-aos="fade-up">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-header">
          <h2>About Us</h2>
        </div>
        <div className="row align-items-center">
          <div className="col-xxl-6 col-xl-5 col-lg-6 col-md-8">
            <div className="about-img about-img1  ">
              <Image src="images/about.png" alt="" />
            </div>
          </div>
          <div className="offset-xl-1 col-xxl-4 col-xl-6 col-lg-6 col-md-9">
            <div className="about-caption about-caption1">

              <div className="section-tittle">
                <h2>Himalayan Healers Spa</h2>
                <p className="mb-3">
                  Welcome to Himalayan Healers Spa, where tranquility meets rejuvenation. Established in 2006, we take pride in being a sanctuary of serenity nestled in the heart of the Himalayas. Our journey began with a vision to create a haven for relaxation, wellness, and healing, drawing inspiration from the natural beauty and timeless traditions of the majestic Himalayan region.
                </p>
              </div>
              <div className="double-btn d-flex flex-wrap">
                <a href="/about-us" className="btn_01">Read More</a>
                <a href="#" className="border-btn">Quik Enquiry</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  );
}
export { HomeAboutUs, AboutUs }
export default function AboutPage(){
  return (
  <> 
    <AboutUs />
  </>
  );
}