import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb'; 
import Carousel from 'react-bootstrap/Carousel';
import ListGroup from 'react-bootstrap/ListGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function DetailAmbassador (){
  const footerStyle = {
      border: "none",
    }
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/branches">Branch</Breadcrumb.Item>
          <Breadcrumb.Item active href="branch/hotel-ambassdor">Hotel Ambassdoor</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="branch-detail mt-5 mb-5">
      <Container>
        <div className="section-header">
          <h2>Hotel Ambassdoor</h2>
        </div>
        <Row>
          <Col xs={12} md={8}>
             <Tabs defaultActiveKey="overview" className="mb-3">
              <Tab eventKey="overview" title="Overview">
              <Carousel data-bs-theme="dark">
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g1.png" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g2.png" alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g4.png" alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g8.png" alt="Fifth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g9.png" alt="Sixth slide" />
                </Carousel.Item>
              </Carousel>
            <div className="overview mt-3">
              <p>Massage therapy is a holistic healing practice that involves the manipulation of 
              oft tissues in the body to promote health and well-being. It has been used for centuries in 
              various cultures around the world. Here are some key points about massage therapy: </p>
            </div>
              </Tab>
              <Tab eventKey="massage_services" title="Massage Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Ayurvedic Treatment</h4>
                    <p>Experience an Indian healing tradition that dates back more then 500 years. A relaxing blend of smooth strokes, energy point work, and muscle friction is 
              designed to alleviate anxiety and balance the body's systems.</p>
                  </div>
                  <div className="service-item">
                    <h4>Wellness Signature</h4>
                    <p>Bring your tired muscles back to life with this relaxing, yet invigorating massage. Your therapist will combine techniques from many modalities 
              and concentrate on problem areas to bring your body back into balance. </p>
                  </div>
                  <div className="service-item">
                    <h4>Rejuvenation Massage</h4>
                    <p>A complete body massage with relaxing techniques which will make energetic creating a deep sense of relaxation. </p>
                  </div>
                  <div className="service-item">
                    <h4>Deep Tissue Treatment</h4>
                    <p>This massage uses a combination of penetrating and relaxing pressure 
              ( often with fists, forearms or elbows) to release deep seated tension. This modality gives particular focus to the back,
               neck and shoulders to ease knotted muscles, remove toxins and relieve pain. </p>
                  </div>
                  <div className="service-item">
                    <h4>Hot Stone Therapy</h4>
                    <p>Feel your body's tension and your cares melt away under the penetrating warmth and gentle pressure of heated, smooth, oiled stones. The therapist alternately massages the body with warm stones in their hands or 
              places stones on major muscle groups for deep and prolonged heat therapy.</p>
                  </div>
                  <div className="service-item">
                    <h4>Reflexology Feet</h4>
                    <p>The feet contain more than 7000 nerve endings. Pressure is placed on a 
              precise reflex area that produces effects in other parts of the body via the pathways of the nervous system, through systematic pressure point work. 
              The foot massage stimulates healing throughout the body.</p>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="parlour_services" title="Parlour Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Fresh Fruit Facial</h4>
                    <p>A natural facial of fresh fruit and pure aroma oil provides deep cleansing and restores the skin's natural balance. Relax into bliss as 
              the face, head, neck and feet are thoroughly massaged. Your skin will glow!</p>
                  </div>
                  <div className="service-item">
                    <h4>Papaya Facial</h4>
                    <p>A facial is a treatment of face with papaya fruits pack. It is rich in vitamin A and best for exfoliating, 
              help rejuvenate your skin and give it a natural glow. </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col xs={12} md={4}>
            <div className="detail-side">
              <Card style={ footerStyle } className="mb-3">
                <Card.Header>Get in Touch</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item><span>Phone:</span> 9851157043</ListGroup.Item>
                  <ListGroup.Item><span>Email:</span> info@himalayanhealersspa.com</ListGroup.Item>
                  <ListGroup.Item><span>Address:</span> Lazimpat, Kathmandu</ListGroup.Item>
                </ListGroup>
              </Card>
              <div className="findLocation mt-3">
                <div className="google-map-code">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.9511203189677!2d85.31495567455184!3d27.718795424988055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c9353d0ef%3A0xa6547a5c10ff5032!2sHotel%20Ambassador!5e0!3m2!1sen!2snp!4v1702379329890!5m2!1sen!2snp" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  );
}

function DetailClubhimalaya (){
  const footerStyle = {
      border: "none",
    }
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/branches">Branch</Breadcrumb.Item>
          <Breadcrumb.Item active href="branch/hotel-club-himalaya">Hotel Club Himalaya</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="branch-detail mt-5 mb-5">
      <Container>
        <div className="section-header">
          <h2>Hotel Club Himalaya</h2>
        </div>
        <Row>
          <Col xs={12} md={8}>
             <Tabs defaultActiveKey="overview" className="mb-3">
              <Tab eventKey="overview" title="Overview">
              <Carousel data-bs-theme="dark">
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g1.png" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g2.png" alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g4.png" alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g8.png" alt="Fifth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g9.png" alt="Sixth slide" />
                </Carousel.Item>
              </Carousel>
            <div className="overview mt-3">
              <p>Massage therapy is a holistic healing practice that involves the manipulation of 
              oft tissues in the body to promote health and well-being. It has been used for centuries in 
              various cultures around the world. Here are some key points about massage therapy: </p>
            </div>
              </Tab>
              <Tab eventKey="massage_services" title="Massage Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Ayurvedic Treatment</h4>
                    <p>Experience an Indian healing tradition that dates back more then 500 years. A relaxing blend of smooth strokes, energy point work, and muscle friction is 
              designed to alleviate anxiety and balance the body's systems.</p>
                  </div>
                  <div className="service-item">
                    <h4>Wellness Signature</h4>
                    <p>Bring your tired muscles back to life with this relaxing, yet invigorating massage. Your therapist will combine techniques from many modalities 
              and concentrate on problem areas to bring your body back into balance. </p>
                  </div>
                  <div className="service-item">
                    <h4>Rejuvenation Massage</h4>
                    <p>A complete body massage with relaxing techniques which will make energetic creating a deep sense of relaxation. </p>
                  </div>
                  <div className="service-item">
                    <h4>Deep Tissue Treatment</h4>
                    <p>This massage uses a combination of penetrating and relaxing pressure 
              ( often with fists, forearms or elbows) to release deep seated tension. This modality gives particular focus to the back,
               neck and shoulders to ease knotted muscles, remove toxins and relieve pain. </p>
                  </div>
                  <div className="service-item">
                    <h4>Hot Stone Therapy</h4>
                    <p>Feel your body's tension and your cares melt away under the penetrating warmth and gentle pressure of heated, smooth, oiled stones. The therapist alternately massages the body with warm stones in their hands or 
              places stones on major muscle groups for deep and prolonged heat therapy.</p>
                  </div>
                  <div className="service-item">
                    <h4>Reflexology Feet</h4>
                    <p>The feet contain more than 7000 nerve endings. Pressure is placed on a 
              precise reflex area that produces effects in other parts of the body via the pathways of the nervous system, through systematic pressure point work. 
              The foot massage stimulates healing throughout the body.</p>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="parlour_services" title="Parlour Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Fresh Fruit Facial</h4>
                    <p>A natural facial of fresh fruit and pure aroma oil provides deep cleansing and restores the skin's natural balance. Relax into bliss as 
              the face, head, neck and feet are thoroughly massaged. Your skin will glow!</p>
                  </div>
                  <div className="service-item">
                    <h4>Papaya Facial</h4>
                    <p>A facial is a treatment of face with papaya fruits pack. It is rich in vitamin A and best for exfoliating, 
              help rejuvenate your skin and give it a natural glow. </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col xs={12} md={4}>
            <div className="detail-side">
              <Card style={ footerStyle } className="mb-3">
                <Card.Header>Get in Touch</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item><span>Phone:</span> +9779851157043</ListGroup.Item>
                  <ListGroup.Item><span>Email:</span> info@himalayanhealersspa.com</ListGroup.Item>
                  <ListGroup.Item><span>Address:</span> Nagarkot, Bhaktapur</ListGroup.Item>
                </ListGroup>
              </Card>
              <div className="findLocation mt-3">
                <div className="google-map-code">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3532.017903968625!2d85.5176786754677!3d27.716733476177122!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb0681e69f4d5f%3A0xd75300924c37f8c7!2sClub%20Himalaya%20by%20ACE%20Hotels!5e0!3m2!1sen!2snp!4v1707544088609!5m2!1sen!2snp" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  );
}

function DetailMountKailash (){
  const footerStyle = {
      border: "none",
    }
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/branches">Branch</Breadcrumb.Item>
          <Breadcrumb.Item active href="branch/mount-kailash-resort">Mount Kailash Resort</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="branch-detail mt-5 mb-5">
      <Container>
        <div className="section-header">
          <h2>Mount Kailash Resort</h2>
        </div>
        <Row>
          <Col xs={12} md={8}>
             <Tabs defaultActiveKey="overview" className="mb-3">
              <Tab eventKey="overview" title="Overview">
              <Carousel data-bs-theme="dark">
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g1.png" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g2.png" alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g4.png" alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g8.png" alt="Fifth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g9.png" alt="Sixth slide" />
                </Carousel.Item>
              </Carousel>
            <div className="overview mt-3">
              <p>Massage therapy is a holistic healing practice that involves the manipulation of 
              oft tissues in the body to promote health and well-being. It has been used for centuries in 
              various cultures around the world. Here are some key points about massage therapy: </p>
            </div>
              </Tab>
              <Tab eventKey="massage_services" title="Massage Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Ayurvedic Treatment</h4>
                    <p>Experience an Indian healing tradition that dates back more then 500 years. A relaxing blend of smooth strokes, energy point work, and muscle friction is 
              designed to alleviate anxiety and balance the body's systems.</p>
                  </div>
                  <div className="service-item">
                    <h4>Wellness Signature</h4>
                    <p>Bring your tired muscles back to life with this relaxing, yet invigorating massage. Your therapist will combine techniques from many modalities 
              and concentrate on problem areas to bring your body back into balance. </p>
                  </div>
                  <div className="service-item">
                    <h4>Rejuvenation Massage</h4>
                    <p>A complete body massage with relaxing techniques which will make energetic creating a deep sense of relaxation. </p>
                  </div>
                  <div className="service-item">
                    <h4>Deep Tissue Treatment</h4>
                    <p>This massage uses a combination of penetrating and relaxing pressure 
              ( often with fists, forearms or elbows) to release deep seated tension. This modality gives particular focus to the back,
               neck and shoulders to ease knotted muscles, remove toxins and relieve pain. </p>
                  </div>
                  <div className="service-item">
                    <h4>Hot Stone Therapy</h4>
                    <p>Feel your body's tension and your cares melt away under the penetrating warmth and gentle pressure of heated, smooth, oiled stones. The therapist alternately massages the body with warm stones in their hands or 
              places stones on major muscle groups for deep and prolonged heat therapy.</p>
                  </div>
                  <div className="service-item">
                    <h4>Reflexology Feet</h4>
                    <p>The feet contain more than 7000 nerve endings. Pressure is placed on a 
              precise reflex area that produces effects in other parts of the body via the pathways of the nervous system, through systematic pressure point work. 
              The foot massage stimulates healing throughout the body.</p>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="parlour_services" title="Parlour Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Fresh Fruit Facial</h4>
                    <p>A natural facial of fresh fruit and pure aroma oil provides deep cleansing and restores the skin's natural balance. Relax into bliss as 
              the face, head, neck and feet are thoroughly massaged. Your skin will glow!</p>
                  </div>
                  <div className="service-item">
                    <h4>Papaya Facial</h4>
                    <p>A facial is a treatment of face with papaya fruits pack. It is rich in vitamin A and best for exfoliating, 
              help rejuvenate your skin and give it a natural glow. </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col xs={12} md={4}>
            <div className="detail-side">
              <Card style={ footerStyle } className="mb-3">
                <Card.Header>Get in Touch</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item><span>Phone:</span> +9779851157043</ListGroup.Item>
                  <ListGroup.Item><span>Email:</span> info@himalayanhealersspa.com</ListGroup.Item>
                  <ListGroup.Item><span>Address:</span> Lakeside, Pokhara</ListGroup.Item>
                </ListGroup>
              </Card>
              <div className="findLocation mt-3">
                <div className="google-map-code">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1758.0455700715397!2d83.9604033171862!3d28.204543435902806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995937f05ee883b%3A0x6fd21498f56473b6!2sHotel%20Mount%20Kailash%20Resort!5e0!3m2!1sen!2snp!4v1707544314064!5m2!1sen!2snp" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  );
}

function HotelLandMarkPokhara (){
  const footerStyle = {
      border: "none",
    }
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/branches">Branch</Breadcrumb.Item>
          <Breadcrumb.Item active href="branch/mount-kailash-resort">Hotel Landmark Pokhara</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="branch-detail mt-5 mb-5">
      <Container>
        <div className="section-header">
          <h2>Hotel Landmark Pokhara</h2>
        </div>
        <Row>
          <Col xs={12} md={8}>
             <Tabs defaultActiveKey="overview" className="mb-3">
              <Tab eventKey="overview" title="Overview">
              <Carousel data-bs-theme="dark">
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g1.png" alt="First slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g2.png" alt="Second slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g4.png" alt="Fourth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g8.png" alt="Fifth slide" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/images/g9.png" alt="Sixth slide" />
                </Carousel.Item>
              </Carousel>
            <div className="overview mt-3">
              <p>Massage therapy is a holistic healing practice that involves the manipulation of 
              oft tissues in the body to promote health and well-being. It has been used for centuries in 
              various cultures around the world. Here are some key points about massage therapy: </p>
            </div>
              </Tab>
              <Tab eventKey="massage_services" title="Massage Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Ayurvedic Treatment</h4>
                    <p>Experience an Indian healing tradition that dates back more then 500 years. A relaxing blend of smooth strokes, energy point work, and muscle friction is 
              designed to alleviate anxiety and balance the body's systems.</p>
                  </div>
                  <div className="service-item">
                    <h4>Wellness Signature</h4>
                    <p>Bring your tired muscles back to life with this relaxing, yet invigorating massage. Your therapist will combine techniques from many modalities 
              and concentrate on problem areas to bring your body back into balance. </p>
                  </div>
                  <div className="service-item">
                    <h4>Rejuvenation Massage</h4>
                    <p>A complete body massage with relaxing techniques which will make energetic creating a deep sense of relaxation. </p>
                  </div>
                  <div className="service-item">
                    <h4>Deep Tissue Treatment</h4>
                    <p>This massage uses a combination of penetrating and relaxing pressure 
              ( often with fists, forearms or elbows) to release deep seated tension. This modality gives particular focus to the back,
               neck and shoulders to ease knotted muscles, remove toxins and relieve pain. </p>
                  </div>
                  <div className="service-item">
                    <h4>Hot Stone Therapy</h4>
                    <p>Feel your body's tension and your cares melt away under the penetrating warmth and gentle pressure of heated, smooth, oiled stones. The therapist alternately massages the body with warm stones in their hands or 
              places stones on major muscle groups for deep and prolonged heat therapy.</p>
                  </div>
                  <div className="service-item">
                    <h4>Reflexology Feet</h4>
                    <p>The feet contain more than 7000 nerve endings. Pressure is placed on a 
              precise reflex area that produces effects in other parts of the body via the pathways of the nervous system, through systematic pressure point work. 
              The foot massage stimulates healing throughout the body.</p>
                  </div>
                </div>
              </Tab>

              <Tab eventKey="parlour_services" title="Parlour Services">
                <div className="branch-service">
                  <div className="service-item">
                    <h4>Fresh Fruit Facial</h4>
                    <p>A natural facial of fresh fruit and pure aroma oil provides deep cleansing and restores the skin's natural balance. Relax into bliss as 
              the face, head, neck and feet are thoroughly massaged. Your skin will glow!</p>
                  </div>
                  <div className="service-item">
                    <h4>Papaya Facial</h4>
                    <p>A facial is a treatment of face with papaya fruits pack. It is rich in vitamin A and best for exfoliating, 
              help rejuvenate your skin and give it a natural glow. </p>
                  </div>
                </div>
              </Tab>
            </Tabs>
          </Col>
          <Col xs={12} md={4}>
            <div className="detail-side">
              <Card style={ footerStyle } className="mb-3">
                <Card.Header>Get in Touch</Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item><span>Phone:</span> +9779851157043</ListGroup.Item>
                  <ListGroup.Item><span>Email:</span> info@himalayanhealersspa.com</ListGroup.Item>
                  <ListGroup.Item><span>Address:</span> Lakeside, Pokhara</ListGroup.Item>
                </ListGroup>
              </Card>
              <div className="findLocation mt-3">
                <div className="google-map-code">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3515.9804525413933!2d83.95502867459855!3d28.20790647589929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3995951eb9f77e43%3A0xbb65a9a7d5ae4a9e!2sLandmark%20Pokhara!5e0!3m2!1sen!2snp!4v1707544481527!5m2!1sen!2snp" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    
    </>
  );
}
export { DetailAmbassador, DetailClubhimalaya , DetailMountKailash, HotelLandMarkPokhara}
// export default function BranchDetailPage(){
//   return (
//   <> 
//     <DetailAmbassador />
//     <DetailClubhimalaya />
//   </>
//   );
// }