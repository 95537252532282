import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { HomeAboutUs} from '../../components/pages/AboutPage.jsx'
import '../../Styles/main.css';
import {FaChevronLeft, FaChevronRight, FaQuoteRight } from "react-icons/fa";
import { BiSolidStar } from "react-icons/bi";
import people from '../../scripts/testimonials.js';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// https://bootstrapmade.com/demo/Impact/
function Homebanner(){
  return (
      <Carousel>
        <Carousel.Item>
          <img className="d-block w-100" src="images/facial.jpg" alt="First slide"/>
          <Carousel.Caption>
            <h5>Welcome to Himalayan Healers Spa</h5>
            <p>In the tender dance of healing hands, find serenity whispered to every inch of your soul.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src="images/accupenture.jpg" alt="Second slide"/>
          <Carousel.Caption>
            <h5>Welcome to Himalayan Healers Spa</h5>
            <p>In the tender dance of healing hands, find serenity whispered to every inch of your soul.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src="images/s1facial.jpg" alt="Third slide"/>
          <Carousel.Caption>
            <h5>Welcome to Himalayan Healers Spa</h5>
            <p>In the tender dance of healing hands, find serenity whispered to every inch of your soul.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
}

function HomeBranches (){
  const myHomeStyle = {
    position: "relative",
    bottom: 130,
    marginBottom: -70,
  };
  useEffect(() => {
        AOS.init();
      }, [])
  return(
    <>
    <section className="home_branches" style={myHomeStyle}>
      <Container data-aos="fade-up">
        <Row>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/clubhimalaya.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Ambassador</Card.Title>
                <Card.Text>Lazimpat, Kathmandu</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-ambassador"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/clubhimalaya.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Himalayan Club</Card.Title>
                <Card.Text>Nagarkot, Bhaktapur</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-club-himalaya"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/mountkailash.jpg)" }}>
              <Card.Body>
                <Card.Title>Mount Kailash Resort</Card.Title>
                <Card.Text>Lakeside, Pokhara</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/mount-kailash-resort"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/landmarkpkr.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Lankmark Pokhara</Card.Title>
                <Card.Text>Lakeside, Pokhara</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-landmark-pokhara"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          {/* <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/clubhimalaya.jpg)" }}>
              <Card.Body>
                <Card.Title>Wellness Santaury</Card.Title>
                <Card.Text>Patan, Lalitpur</Card.Text>
                <Card.Text>Phone: 9851133463</Card.Text>
                <Card.Text>Email:wellnesssanctuarynepal@gmail.com</Card.Text>
                <Card.Link href="/branch/mount-kailash-resort"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </section>
    </>
    );
}

function HomeService (){
  useEffect(() => {
        AOS.init();
      }, [])
  return(
    <>
    <section id="services" className="services">
      <Container className="aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
        <div className="section-header">
          <h2>Our Services</h2>
        </div>
        <Row>
          <Col lg={4} sx={12} md={6}>
            <div className="service-item  service-fold">

              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Ayurvedic Treatment</h3>
              <p>Experience an Indian healing tradition that dates back more then 500 years. A relaxing blend of smooth strokes, energy point work, and muscle friction is 
              designed to alleviate anxiety and balance the body's systems.</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Wellness Signature</h3>
              <p>Bring your tired muscles back to life with this relaxing, yet invigorating massage. Your therapist will combine techniques from many modalities 
              and concentrate on problem areas to bring your body back into balance.</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Rejuvenation Massage</h3>
              <p>A complete body massage with relaxing techniques which will make energetic creating a deep sense of relaxation.  </p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Deep Tissue Treatment</h3>
              <p>This massage uses a combination of penetrating and relaxing pressure 
              ( often with fists, forearms or elbows) to release deep seated tension. This modality gives particular focus to the back,
               neck and shoulders to ease knotted muscles, remove toxins and relieve pain.</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Hot Stone Therapy</h3>
              <p>Feel your body's tension and your cares melt away under the penetrating warmth and gentle pressure of heated, smooth, oiled stones. The therapist alternately massages the body with warm stones in their hands or 
              places stones on major muscle groups for deep and prolonged heat therapy.</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Reflexology feet</h3>
              <p>The feet contain more than 7000 nerve endings. Pressure is placed on a 
              precise reflex area that produces effects in other parts of the body via the pathways of the nervous system, through systematic pressure point work. 
              The foot massage stimulates healing throughout the body.   </p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Feet Treat</h3>
              <p>To make your feet happy we trim your nails, cleanse and providing scrub and mud wraps on foot and massage with moisturizer.</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Fresh Fruit Facial</h3>
              <p>A natural facial of fresh fruit and pure aroma oil provides deep cleansing and restores the skin's natural balance. Relax into bliss as 
              the face, head, neck and feet are thoroughly massaged. Your skin will glow!</p>
            </div>
          </Col>

          <Col lg={4} sx={12} md={6}>
            <div className="service-item position-relative">
              <div className="icon mb-3">
                <BiSolidStar size={50} />
              </div>
              <h3>Papaya Facial</h3>
              <p>A facial is a treatment of face with papaya fruits pack. It is rich in vitamin A and best for exfoliating, 
              help rejuvenate your skin and give it a natural glow.   </p>
            </div>
          </Col>

        </Row>

      </Container>
    </section>
    </>
  );
}

const HomeTestimonial = () => {
  const [index, setIndex] = useState(0);
  const {name, job, image, text} = people[index];

  const checkNumber = (number) => {
    if(number > people.length - 1){
      return 0;
    }
    else if(number < 0){
      return people.length - 1;
    }
    return number;
  }

  const nextPerson = () => {
    setIndex((index) => {
      let newIndex = index + 1;
      return checkNumber(newIndex);
    }) 
  };

  const prevPerson = () => {
    setIndex((index) => {
      let newIndex = index - 1;
      return checkNumber(newIndex);
    }) 
  }

  return(
        <>
        <section className="main section-bg aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
          <Container>
            <div className="section-header">
              <h2>What Our Client Says?</h2>
            </div>
            <article className="review">
              <div className="img-container">
                <img src={image} alt={name} className="person-img"/>
                <span className="quote-icon">
                  <FaQuoteRight />
                </span>
              </div>
              <h4 className="author">{name}</h4>
              <p className="jon">{job}</p>
              <p className="info">{text}</p>
              <div className="button-container">
                <button className="prev-btn" onClick={prevPerson}>
                  <FaChevronLeft />
                </button>
                <button className="next-btn" onClick={nextPerson}>
                  <FaChevronRight />
                </button>  
              </div>
            </article>
          </Container>
        </section>
        </>
        );
};

export default function HomePage(){
  return (
  <> 
    <Homebanner />
    <HomeBranches />
    <HomeAboutUs />
    <HomeService />
    <HomeTestimonial />
  </>
  );
}