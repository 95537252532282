import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

function ContactUs (){
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/contact-us">Contact</Breadcrumb.Item>
          <Breadcrumb.Item active>Contact Us</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>

    <section className="contact-page mb-5 mt-5">
      <Container>
        <div className="section-header">
          <h2>Contact Us</h2>
        </div>
        <Row>
          <Col md={6} className="contact-detail">
            <div className="contectInfo">
              <h3>Find Us</h3>
              <ul>
                <li><span>Address:</span> Hotel Ambassador, Lazimpat, Kathmandu</li>
                <li><span>Phone:</span> +9779851157043</li>
                <li><span>Email:</span> info@himalayanhealersspa.com</li>
              </ul>
            </div>
            <div className="branchInfo mt-4">
              <h3>Other Branches</h3>
              <ul className="mt-2">
              <li><a href="/branch/hotel-ambassador"><h5>Hotel Ambassdoor </h5></a></li>
                <li><a href="/branch/hotel-club-himalaya"><h5>Hotel Club Himalaya</h5></a></li>
                <li><a href="/branch/mount-kailash-resort"><h5>Mount Kailash Resort </h5></a></li>
                <li><a href="/branch/hotel-landmark-pokhara"><h5>Hotel Landmark Pokhara </h5></a></li>
                
              </ul>
            </div>

          </Col>
          <Col md={6}>
            <div className="contact-title mb-3">
              <h3>Get In Touch</h3>
            </div>
            <Form>
            <Form.Group as={Row} className="mb-3" controlId="formPlainText">
                <Form.Label column sm="2">
                  Full Name
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="name" placeholder="Your Full Name" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="text" name="email" placeholder="Your Email" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3" controlId="formPlaintextNumber">
                <Form.Label column sm="2">
                  Phone no.
                </Form.Label>
                <Col sm="10">
                  <Form.Control name="phone" type="text" placeholder="Your Phone Number" />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3" controlId="formPlainText">
                <Form.Label column sm="2">
                  Message
                </Form.Label>
                <Col sm="10">
                  <Form.Control as="textarea" name="message" rows={3} />
                </Col>
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Submit
              </Button>
            </Form>
          </Col>

          <Col md={12} className="locationMap mt-5">
             <div className="google-map-code">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3531.9511203189677!2d85.31495567455184!3d27.718795424988055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb191c9353d0ef%3A0xa6547a5c10ff5032!2sHotel%20Ambassador!5e0!3m2!1sen!2snp!4v1702379329890!5m2!1sen!2snp" width="100%" height="450" frameBorder="0" style={{border:0}} allowFullScreen aria-hidden="false" tabIndex="0"></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    </>
  );
}

export default function ContactPage(){
  return (
  <> 
    <ContactUs />
  </>
  );
}