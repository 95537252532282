import { Container} from 'react-bootstrap';
import React from "react";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import 'bootstrap/dist/js/bootstrap.min.js';
import GalleryJs from '../../scripts/gallery.js';

function Gallery (){
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/gallery" active>Gallery</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="photo-gallery mb-5 mt-5">
      <div className="container">
        <div className="section-header">
          <h2>Our Photo Gallery</h2>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4 gallery-grid">
          <div className="col">
            <a className="gallery-item" href="images/g1.png">
              <img src="images/g1.png" className="img-fluid" alt="Lorem ipsum dolor sit amet" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g2.png">
              <img src="images/g2.png" className="img-fluid" alt="Ipsum lorem dolor sit amet" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g3.png">
              <img src="images/g3.png" className="img-fluid" alt="Dolor lorem ipsum sit amet" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g4.png">
              <img src="images/g4.png" className="img-fluid" alt="Sit amet lorem ipsum dolor" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g5.png">
              <img src="images/g5.png" className="img-fluid" alt="Aut ipsam deserunt nostrum quo" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g6.png">
              <img src="images/g6.png" className="img-fluid" alt="Nulla ex nihil eligendi tempora" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g8.png">
              <img src="images/g8.png" className="img-fluid" alt="Nemo perspiciatis voluptatum" />
            </a>
          </div>
          <div className="col">
            <a className="gallery-item" href="images/g9.png">
              <img src="images/g9.png" className="img-fluid" alt="Dolore asperiores reprehenderit" />
            </a>
          </div>
        </div>
      </div>
      <div className="modal fade lightbox-modal" id="lightbox-modal" tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered modal-fullscreen">
        <div className="modal-content">
          <button type="button" className="btn-fullscreen-enlarge" aria-label="Enlarge fullscreen">
            <svg className="bi"><use href="#enlarge"></use></svg>
          </button>
          <button type="button" className="btn-fullscreen-exit d-none" aria-label="Exit fullscreen">
            <svg className="bi"><use href="#exit"></use></svg>
          </button>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          <div className="modal-body">
            <div className="lightbox-content">

            </div>
          </div>
        </div>
      </div>
      </div>
    </section>

    </>
  );
}

export default function GalleryPage(){
  return (
  <> 
    <Gallery />
  </>
  );
}