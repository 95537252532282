import logo from './logo.svg';
import React from 'react';
import './App.css';
import TopNavbar from './components/common/Header';
import Footer from './components/common/Footer';
import HomePage from './components/pages/HomePage';

function App() {
  return (
    <React.Fragment>
      <TopNavbar/>
      <Footer />
    </React.Fragment>
  );
}

export default App;
