const reviews = [
  {
    id: 1,
    name: 'Susan Smith',
    job: 'Canada',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883334/person-1_rfzshl.jpg',
    text:
      "A transformative experience that melted away tension and left me floating on a cloud of relaxation. The skilled therapists and serene ambiance make this massage service a true haven for rejuvenation.",
  },
  {
    id: 2,
    name: 'Anna Johnson',
    job: 'Australia',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883409/person-2_np9x5l.jpg',
    text:
      'Exceptional massage service that goes beyond the ordinary. The therapists have a magical touch, and the attention to detail in creating a peaceful atmosphere ensures a blissful escape from the everyday hustle.',
  },
  {
    id: 3,
    name: 'Peter Pones',
    job: 'German',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883417/person-3_ipa0mj.jpg',
    text:
      'Impeccable professionalism and personalized care define this massage service. From the moment you walk in, you are enveloped in a cocoon of tranquility, and each massage is a tailored journey to wellness.',
  },
  {
    id: 4,
    name: 'Bill Anderson',
    job: 'Canada',
    image:
      'https://res.cloudinary.com/diqqf3eq2/image/upload/v1586883423/person-4_t9nxjt.jpg',
    text:
      'An oasis of calm and skilled hands that seem to intuitively find and release tension. The massage service here is a sanctuary for anyone seeking not just relaxation, but a genuine renewal of mind and body.',
  },
];

export default reviews;
