import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import { FaFacebookSquare,FaEnvelopeSquare, FaYoutubeSquare, FaInstagramSquare  } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Footer = () => {
    let date = new Date();
    let year = date.getFullYear();

    const footerStyle = {
      border: "none",
      backgroundColor: "#f3a2a2",
      marginLeft: "-10px",
    }
    const footerTripImg = {
      height: "40px",
    }
    return (
      <section className="footer">
        <Container>
          <Row>
            <Col md={4} className="footer-link">
              <div className="footer-text">
                <h4>Quick Links</h4>
              </div>
              <ListGroup as="ul">
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/home" className="fw-bold">Home</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/about-us" className="fw-bold">About Us</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/services" className="fw-bold">Services</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/branches" className="fw-bold">Branches</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/contact-us" className="fw-bold">Contact Us</a>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4} className="footer-link">
              <div className="footer-text">
                <h4>Our Branches</h4>
              </div>
              <ListGroup as="ul">
                <ListGroup.Item style={footerStyle} as="li">
                  <div className="me-auto">
                    <a href="/branch/hotel-ambassador" className="fw-bold">Hotel Ambassador</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li" className="d-flex justify-content-between align-items-start">
                  <div className="me-auto">
                    <a href="/branch/hotel-club-himalaya" className="fw-bold">Hotel Club Himalaya</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li" className="d-flex justify-content-between align-items-start">
                  <div className="me-auto">
                    <a href="/branch/mount-kailash-resort" className="fw-bold">Mount Kailash Resort</a>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item style={footerStyle} as="li" className="d-flex justify-content-between align-items-start">
                  <div className="me-auto">
                    <a href="/branch/hotel-landmark-pokhara" className="fw-bold">Hotel Landmark Pokhara</a>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={4}  className="footer-link">
              <div className="footer-text">
                <h4>Head Office</h4>
                <p>Phone: +9779851157043 </p>
                <p>Email: info@himalayanhealersspa.com </p>
                <p>Address: Lazimpat, Kathmandu</p>
              </div>
              <ul className="d-flex social-media">
                <li><a href="/#" className="fw-bold" title="Facebook"><FaFacebookSquare size={30} /></a></li>
                <li><a href="/#" className="fw-bold" title="Twitter"><FaSquareXTwitter size={30} /></a></li>
                <li><a href="/#" className="fw-bold" title="Email"><FaEnvelopeSquare size={30} /></a></li>
                <li><a href="/#" className="fw-bold" title="Youtube"><FaYoutubeSquare size={30} /></a></li>
                <li><a href="/#" className="fw-bold" title="Instagram"><FaInstagramSquare size={30} /></a></li>
                <br />
              </ul>
              <div className="tripAdvisor">
                <a href="/#" title="Trip Advisor">
                  <img src="/images/tripadvisor.svg" style={footerTripImg} className="img-fluid" alt="Trip Advisor" />
                </a>
              </div>
            </Col>
            <Col md="12" className="footer-copywright mt-4">
                <span>Copyright © {year} Himalayan Healers Spa All rights reserve.</span>
            </Col>
          </Row>
        </Container>
      </section>
    );  
}

export default Footer