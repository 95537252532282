import React, {useState} from "react";
import '../../Styles/main.css';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import HomePage from '../../components/pages/HomePage.jsx';
import AboutPage from '../../components/pages/AboutPage.jsx';
import ContactPage from '../../components/pages/ContactPage.jsx';
import ServiceList from '../../components/pages/ServicePage.jsx';
import BranchList from '../../components/pages/BranchListPage.jsx';
import { DetailAmbassador, DetailClubhimalaya, DetailMountKailash, HotelLandMarkPokhara} from '../../components/pages/BranchDetailPage.jsx';
import Gallery from '../../components/pages/GalleryPage.jsx'
import { Container, Button} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Router, Routes, Route, Link} from "react-router-dom";

function TopNavbar() {
    const [expand, updateExpanded] = useState(false);
    return (
      <Router>
        <Navbar expanded={expand} sticky="top" expand="lg" className="top_nav">
          <Container>
            <Navbar.Brand as={Link} to={"/home"} onClick={()=> updateExpanded(false)}>
            <Image src="/images/logor.png" alt="Logo" className="img-responsive" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => {
                updateExpanded(expand ? false : "expanded");
              }}/>
            <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
              <Nav>
                <Nav.Link as={Link} to={"/home"} onClick={()=> updateExpanded(false)}>Home</Nav.Link>
                <Nav.Link as={Link} to={"/about-us"} onClick={()=> updateExpanded(false)}>About Us</Nav.Link>
                <Nav.Link as={Link} to={"/services"} onClick={()=> updateExpanded(false)}>Services</Nav.Link>
                <NavDropdown title="Branches" id="collapsible-nav-dropdown">
                  <NavDropdown.Item as={Link} to={"/branch/hotel-ambassador"} onClick={()=> updateExpanded(false)}>Hotel Ambassador</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={"/branch/hotel-club-himalaya"} onClick={()=> updateExpanded(false)}>Hotel Club Himalaya</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={"/branch/mount-kailash-resort"} onClick={()=> updateExpanded(false)}>Mount Kailash Resort</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item as={Link} to={"/branch/hotel-landmark-pokhara"} onClick={()=> updateExpanded(false)}>Hotel Landmark Pokhara</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to={"/gallery"} onClick={()=> updateExpanded(false)}>Gallery</Nav.Link>
                <Nav.Link as={Link} to={"/contact-us"} onClick={()=> updateExpanded(false)}>Contact Us</Nav.Link>
              </Nav>
              <Nav>
                <Nav.Link href="#deets">
                    <Button className="btn btn-primary"> Book Now </Button>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div>
          <Routes>
            <Route path='/' element={<HomePage/>} />
            <Route path='/home' element={<HomePage/>} />
            <Route path='/about-us' element={<AboutPage/>} />
            <Route path='/contact-us' element={<ContactPage/>} />
            <Route path='/services' element={<ServiceList/>} />
            <Route path='/branches' element={<BranchList/>} />
            <Route path='/gallery' element={<Gallery/>} />
            <Route path='/branch/hotel-ambassador' element={<DetailAmbassador/>} />
            <Route path='/branch/hotel-club-himalaya' element={<DetailClubhimalaya/>} />
            <Route path='/branch/mount-kailash-resort' element={<DetailMountKailash/>} />
            <Route path='/branch/hotel-landmark-pokhara' element={<HotelLandMarkPokhara/>} />
          </Routes>
        </div>
      </Router>
    );
}

export default TopNavbar;