import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Pagination from 'react-bootstrap/Pagination'; 
function BranchList (){
  return(
    <>
    <section className="breadcrumb" style={{ backgroundImage: "url(/images/bread.jpg)" }}>
      <Container>
        <Breadcrumb>
          <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
          <Breadcrumb.Item active href="/branches">Branch List</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </section>
    <section className="list_branches">
      <Container>
        <div className="section-header">
          <h2>All Branches</h2>
        </div>
        <Row>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/clubhimalaya.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Ambassador</Card.Title>
                <Card.Text>Lazimpat, Kathmandu</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-ambassador"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/clubhimalaya.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Himalayan Club</Card.Title>
                <Card.Text>Nagarkot, Bhaktapur</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-club-himalaya"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/mountkailash.jpg)" }}>
              <Card.Body>
                <Card.Title>Mount Kailash Resort</Card.Title>
                <Card.Text>Lakeside, Pokhara</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/mount-kailash-resort"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} sm={6} md={3}>
            <Card style={{ backgroundImage: "url(/images/landmarkpkr.jpg)" }}>
              <Card.Body>
                <Card.Title>Hotel Lankmark Pokhara</Card.Title>
                <Card.Text>Lakeside, Pokhara</Card.Text>
                <Card.Text>Phone: 9851157043</Card.Text>
                <Card.Text>Email:info@himalayanhealersspa.com</Card.Text>
                <Card.Link href="/branch/hotel-landmark-pokhara"> 
                  <Button variant="warning">View Detail</Button>
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Pagination> 
          <Pagination.Prev /> 
            <Pagination.Ellipsis /> 
              <Pagination.Item>{3}</Pagination.Item> 
              <Pagination.Item>{4}</Pagination.Item> 
              <Pagination.Item>{5}</Pagination.Item> 
            <Pagination.Ellipsis /> 
          <Pagination.Next /> 
        </Pagination> 
      </Container>
    </section>
    
    </>
  );
}
export { BranchList }
export default function BranchListPage(){
  return (
  <> 
    <BranchList />
  </>
  );
}